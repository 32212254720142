import { gql } from "@apollo/client"

//------ QUERIES ------

// ------------------------------- To get users list -----------------------

export const LIST_USERS_QUERY = gql`
  query ListUsersQuery {
    users {
      id
      username
    }
  }
`

// ------------------------------- To get education Specialization ---------------

export const EDUCATION_SPECIALIZATION = gql`
  query getSpecialization{
    educationSpecializations{
      id
      title
    }
  }
`



// ------------------------------- To get Country list ---------------------------

export const COUNTRY_QUERY = gql`
  query CountryQuery {
    countries(sort: "countryName:asc") {
      id
      countryName
    }
  }
`
// ------------------------------- To get extra carricular Activity ---------------------

export const EXTRA_CARRICULAR_ACTIVITY = gql`
  query ExtraCarricularQuery {
    extraCarricularActivities(sort: "ActivityName:asc") {
    id
    ActivityName
    }
  }
`

// ------------------------------- Toget Highest level of education ----------------

export const HIGHEST_LEVEL_OF_EDUCATION = gql`
query getHighLevelOfEDucation {
  highestLevelOfEducations{
    id
    Education
  }
}
`

// ------------------------------- To get courser based on level of education

export const EDUCATION_COURSE = gql`
query getEducationCourse($id: ID!){
  degreeDiplomaCerificates(where:{highest_level_of_educations:{id:$id}}){
  id
  title
  }
}

`

// ------------------------------- To get courser based on level of education

export const ALL_EDUCATION_COURSE = gql`
query getAllEducationCourse{
  degreeDiplomaCerificates{
  id
  title
  }
}

`

// ------------------------------- To get particular country ----------------------------
export const COUNTRY_ID_QUERY = gql`
  query CountryQuery {
    countries(where: { countryName: "France" }) {
      id
      countryName
    }
  }
`
// ------------------------------- To get Study Abroad Exam list ---------------------------

export const STUDY_ABROAD_EXAM_QUERY = gql`
  query studyAbroadExam {
    studyAbroadExams {
      id
      examName
    }
  }
`
// ------------------------------- To get particular user -----------------------

export const USER_QUERY = gql`
  query userData($id: ID!) {
    user(id: $id) {
      id
      username
      state
      cityName
      zipcode
      address
      email
      Gender
      currentLocation
      state
      secondaryEmail
      firstName
      lastName
      mobileNumber
      dateOfBirth
      cityName
      mailOTP
      mobileOTP
      isMobileVerified
      isMailVerified
      middleName
      Gender
      maritalStatus
      isDiffrentlyAbled
      passportNo
      passportIssueDate
      passportExpiryDate
      placeOfBirthAsPerPassport
      adhaarCardNo
      salutaion
      emergencyNomineeName
      emergencyNomineeContact
      nomineeRelation
      levelOfFrench
      passportIssueCountry{
        id
        countryName
      }
      countryOfBirth{
        id
        countryName
      }
      nationality{
        id
        countryName
      }
      countryName {
        id
        countryName
      }
      profileImage {
        id
        url
        name
      }
      profileImageContent
      userRole {
        name
      }
      journeyStepContent {
        journeystep {
          id
          name
          thumbnailImage {
            id
            url
            name
          }
          altThumbnailName
        }
        position
        active
      }
      educationBackground {
        id
        courseName
        instituteName
        universityName
        markValue
        educationStatus
        specialization
        gradingSystem
        universityCity
        universityState
        universityCountry{
          id
          countryName
        }
        highest_level_of_education{
          id
          Education
        }
      },
      futureEducationPreferences {
        id
        courseName
        specialization
        planningToJoin
        country {
          id
          countryName
        }
        locationName
      },
      workExperience {
        id
        workType
        workStatus
        companyName
        designation
        jobRole
        startFrom
        endOn
        locationName
        country{
          id
          countryName
        }
      },
      studyAbroadExamContent {
        studyAbroadExam {
          id
          examName
        }
        markValue
      }
      companyDetail {
        id
        companyName
        companyURL
        companyAddress
      }
      IDDocuments{
        id
        PassportBack{
          id
          name
          url
        },
        Passport{
          id
          name
          url
        },
        Cv{
          id
          name
          url
        }
      },
      extraCarricularActivitys{
        id
        Status
        extra_carricular_activity
        position
        description
        startedFrom
        EndedOn
      },
      testscore{
        plusTwoMark
        GRE
        GMAT
        TOEFL
        IELTS
        DEFL
        otherTestName
        otherTestScore
      },
      Recommendation{
        id
        ReferenceType
        RecommenderName
        RecommenderDesignation
        AddressofOrganisation
        nameOfOrganization
        officialEmail
        RelationWithRecommender
        ContactNumber
        RecommendationLatter
      },
      EDUDocuments{
        sslc{
          name
          id
          url
        },
          plustwo{
            name
          id
          url
          },
          UGCertificate{
            name
          id
          url
          },
          UGMarksheet{
            name
          id
          url
          },
          UGConsolidateMarksheet{
            name
          id
          url
          },
          PGMarksheet{
            name
          id
          url
          },
          PGCertificate{
            name
          id
          url
          },
          PGConsolidateMarksheet{
            name
          id
          url
          }
          
        }
    }
  }
`

// -------------------------------- To get Documents ------------------------------------------

export const USER_DOCUMENTS = gql`
query userData($id: ID!) {
  user(id: $id) {
    IDDocuments{
      Passport{
        id
        name
        url
      },
      PassportBack{
        id
        name
        url
      },
      Cv{
        id
        name
        url
      }
    },
    EDUDocuments{
      sslc{
        name
        id
        url
      },
        plustwo{
          name
        id
        url
        },
        UGCertificate{
          name
        id
        url
        },
        UGMarksheet{
          name
        id
        url
        },
        UGConsolidateMarksheet{
          name
        id
        url
        },
        PGMarksheet{
          name
        id
        url
        },
        PGCertificate{
          name
        id
        url
        },
        PGConsolidateMarksheet{
          name
        id
        url
        }
        
    },
    WorkExperianceDocuments{
      company
      id
      WorkExperianceCerificate{
        name
        url
        id
      }
    },
    EnglishProficiencyDocs{
      Test
      EnglishProficiency{
        id
        name
        url
      }
    },
    otherDocs{
      id
      name
      Document{
        id
        name
        url
      }
    },
    RecommendationDocuments{
      id
      Certificate{
        id
        name
        url
      }
      Name
    },
    ExtraCarricular{
      Certificate{
        id
        name
        url
      }
      Title
      id
    }
  }
}
`


// -------------------------------- To get user journey articles ------------------------------

export const USER_JOURNEY_ARTICLES_QUERY = gql`
  query getJourneyStep($id: ID!) {
    journeyStep(id: $id) {
      id
      name
      articles(sort: "createdAt:desc") {
        id
        createdAt
        author {
          id
          firstName
          lastName
          biography
          profileImage {
            id
            name
            url
          }
          altProfileName
          userRole {
            id
            name
          }
        }
        topic {
          id
          title
          thumbnail {
            id
            name
            url
          }
          altThumbnailName
        }
        title
        url
        description
        image {
          id
          name
          url
        }
        sectionContent {
          id
          title
          description
          position
        }
        wishlistCount
        favoriteCount
        ReadingTimeInMinutes
        blogDate
      }
    }
  }
`

// -------------------------------- To get footer content ------------------------------

export const FOOTER = gql`
  query {
    footerBlock {
      id
      logoImage {
        id
        name
        url
      }
      companyName
      companySlogan
      socialMediaContent {
        id
        socialIconURL
        socialMediaIcon {
          id
          name
          url
        }
      }
      menuContent {
        id
        menuName
        menuURL
      }
      websiteURL
      contactInfo {
        id
        mailAddress
        contactNumber
      }
      companyAddress
      mapButtonName
      mapURL
      copyRightContent
      copyRightYear
    }
  }
`

//--------------------------------- TO get Consultation Block content --------------------

export const CONSULTATION_BLOCK = gql`
  query consultationBlock {
    consultationBlock {
      id
      title1
      title2
      shortDescription
      altthumbnailName
      thumbnailImage {
        name
        url
        id
      }
      buttonName
    }
  }
`
// -------------------------------- TO get study in france content ----------------------

export const STUDY_IN_FRANCE = gql`
  query studyInFrance {
    studyInFrance {
      id
      title
      description
      altVideoName
      video {
        id
        name
        url
      }
      urlPath
      metaDescription
      studyListing(sort: "position:asc") {
        id
        listingTitle
        menuName
        url
        thumbnailImage {
          name
          id
          url
        }
        altThumbnailName
        position
        bannerImage {
          id
          name
          url
        }
        altBannerName
        bannerTitle
        shortDescription
        longDescription
        sectionTitle
        sectionContent {
          id
          title
          description
          position
          subContent {
            id
            title
            description
          }
        }
        title
        metaDescription
      }
    }
  }
`

// -------------------------------- TO get study in france categories list ----------------------

export const STUDY_LISTING = gql`
  query studyListing($id: ID!) {
    studyListing(id: $id) {
      id
      listingTitle
      url
      thumbnailImage {
        name
        id
        url
      }
      altThumbnailName
      position
      bannerTitle
      bannerImage {
        id
        name
        url
      }
      altBannerName
      shortDescription
      longDescription
      sectionTitle
      sectionContent {
        id
        title
        description
        position
        subContent {
          id
          title
          description
        }
      }
      title
      metaDescription
    }
  }
`
// -------------------------------- TO check subscriber already available ----------------------

export const VALIDATE_NEWSLETTER_SUBSCRIBER = gql`
  query newsletterSubscribers($subscriber: String!) {
    newsletterSubscribers(where: { Subscriber: $subscriber }) {
      id
      Subscriber
    }
  }
`
// -------------------------------- TO get newsletter block content ----------------------

export const NEWSLETTER_BLOCK = gql`
  query newsletter_block {
    newsletterBlock {
      id
      title
      shortDescription
      altthumbnailName
      thumbnailImage {
        id
        url
      }
      buttonName
    }
  }
`

// -------------------------------- TO check email id already available ----------------------

export const VALIDATE_USER_EMAIL_ID = gql`
  query validateUserEmailID($email: String!) {
    users(where: { email: $email }) {
      id
      email
      secondaryEmail
    }
  }
`
//---------------------------------------- To get all user roles -----------------------------

export const GET_ALL_USER_ROLES = gql`
  query {
    userRoles(where: { name_containss: ["Student", "Alumni", "Consultant"] }) {
      id
      name
    }
  }
`

//---------------------------------------- To get all journey step data -----------------------------

export const JOURNEY_STEP_QUERY = gql`
  query {
    journeySteps {
      id
    }
  }
`

//------------------------------------------------------ Search by blog name -----------------------------------

export const SEARCH_BLOG_BY_VALUES = gql`
  query getArticlesInfo($searchValue: String) {
    articles(where: { title_contains: $searchValue }) {
      id
      articleTypes {
        id
        type
      }
      author {
        id
        firstName
        lastName
        biography
        profileImage {
          id
          name
          url
        }
        altProfileName
        userRole {
          id
          name
        }
      }
      topic {
        id
        title
        thumbnail {
          id
          name
          url
        }
        altThumbnailName
      }
      title
      url
      description
      image {
        id
        name
        url
      }
      sectionContent {
        id
        title
        description
        position
      }
      wishlistCount
      favoriteCount
      ReadingTimeInMinutes
      blogDate
    }
  }
`

export const BLOG_BY_SELECTEDTOPIC = gql`
  query getArticlesbyTopic($selectedTopicID: String) {
    articles(
      where: { topic: { id: $selectedTopicID } }
      sort: "createdAt:desc"
    ) {
      id
      articleTypes {
        id
        type
      }
      author {
        id
        firstName
        lastName
        biography
        profileImage {
          id
          name
          url
        }
        altProfileName
        userRole {
          id
          name
        }
      }
      topic {
        id
        title
        thumbnail {
          id
          name
          url
        }
        altThumbnailName
      }
      title
      url
      description
      image {
        id
        name
        url
      }
      sectionContent {
        id
        title
        description
        position
      }
      wishlistCount
      favoriteCount
      ReadingTimeInMinutes
      blogDate
      metaDescription
    }
  }
`

//------ MUTATIONS ------

// ------------------------------- To register user -----------------------

export const CREATE_USER_MUTATION = gql`
  mutation createUser($email: String!, $password: String!, $username: String!) {
    register(
      input: { username: $username, password: $password, email: $email }
    ) {
      jwt
      user {
        id
        username
        email
      }
    }
  }
`
// ------------------------------- To login user  -----------------------

export const LOGIN_USER_MUTATION = gql`
  mutation loginUser($identifier: String!, $password: String!) {
    login(input: { identifier: $identifier, password: $password }) {
      jwt
      user {
        id
        username
        email
      }
    }
  }
`
//---------------------------------Auto login user -------------------------
export const AUTO_LOGIN_USER_MUTATTION = gql`
  mutation autoLoginUser($identifier: String!, $autologin: String) {
    autoLogin(input: { identifier: $identifier, autologin: $autologin }) {
      jwt
      user {
        id
        username
        email
      }
    }
  }
`
// -------------------------------- TO create newsletter subscriber ----------------------

export const NEWSLETTER_SUBSCRIBE_MUTATION = gql`
  mutation newsletterSubscriber(
    $newsletterInput: createNewsletterSubscriberInput!
  ) {
    createNewsletterSubscriber(input: $newsletterInput) {
      newsletterSubscriber {
        id
        Subscriber
      }
    }
  }
`
// -------------------------------- TO Update User information ----------------------

export const UPDATE_USER_MUTATION = gql`
  mutation updateUserDetails(
    $id: ID!
    $firstName: String!
    $lastName: String!
    $username: String!
    $mobileNumber: String!
    $dateOfBirth: Date!
    $email: String!
    $cityName: String!
    $countryID: ID!
    $mailOTP: String!
    $mobileOTP: String!
    $isMobileVerified: Boolean!
    $isMailVerified: Boolean!
  ) {
    updateUser(
      input: {
        where: { id: $id }
        data: {
          firstName: $firstName
          lastName: $lastName
          username: $username
          mobileNumber: $mobileNumber
          dateOfBirth: $dateOfBirth
          email: $email
          secondaryEmail: $email
          cityName: $cityName
          countryName: $countryID
          mailOTP: $mailOTP
          mobileOTP: $mobileOTP
          isMobileVerified: $isMobileVerified
          isMailVerified: $isMailVerified
        }
      }
    ) {
      user {
        username
        email
        secondaryEmail
        firstName
        lastName
        mobileNumber
        dateOfBirth
        cityName
        mailOTP
        mobileOTP
        isMailVerified
        isMobileVerified
        countryName {
          id
          countryName
        }
      }
    }
  }
`
export const UPDATE_USER_ROLE_MUTATION = gql`
  mutation updateUserDetails($userId: ID!, $role: ID!) {
    updateUser(input: { where: { id: $userId }, data: { userRole: $role } }) {
      user {
        userRole {
          id
          name
        }
      }
    }
  }
`

//------------------------------------- To Add and Update user education background ------------------

export const UPDATE_USER_EDUCATION_BACKGROUND = gql`
  mutation updateEducationBackground(
    $id: ID!
    $eContent: [editComponentPluginEducationBackgroundInput]
  ) {
    updateUser(
      input: { where: { id: $id }, data: { educationBackground: $eContent } }
    ) {
      user {
        educationBackground {
          id
          courseName
          boardName
          universityOrInstitute
          locationName
          markValue
          passingOutYear
          gradingSystem
        }
      }
    }
  }
`
//------------------------------------- To Update user future education preferences ------------------

export const UPDATE_USER_FUTURE_EDUCATION_PREFERENCES = gql`
  mutation updateFutureEducationPreferences(
    $id: ID!
    $eContent: [editComponentPluginFutureEducationPreferenceInput]
  ) {
    updateUser(
      input: {
        where: { id: $id }
        data: { futureEducationPreferences: $eContent }
      }
    ) {
      user {
        futureEducationPreferences {
          id
          courseName
          specialization
          planningToJoin
          locationName
          country {
            id
            countryName
          }
        }
      }
    }
  }
`
//------------------------------------- To Add and Update user work experiences ------------------

export const UPDATE_USER_WORK_EXPERIENCE = gql`
  mutation updateWorkExperience(
    $id: ID!
    $eContent: [editComponentPluginWorkExperienceInput]
  ) {
    updateUser(
      input: { where: { id: $id }, data: { workExperience: $eContent } }
    ) {
      user {
        workExperience {
          id
          jobTitle
          companyName
          workType
          country {
            id
            countryName
          }
          locationName
          fromMonth
          fromYear
          toMonth
          toYear
          isPresent
        }
      }
    }
  }
`
//------------------------------------- To Add and Update user company details ------------------

export const UPDATE_USER_COMPANY_DETAILS = gql`
  mutation updateCompanyDetails(
    $id: ID!
    $eContent: [editComponentPluginCompanyDetailInput]
  ) {
    updateUser(
      input: { where: { id: $id }, data: { companyDetail: $eContent } }
    ) {
      user {
        companyDetail {
          id
          companyName
          companyURL
          companyAddress
        }
      }
    }
  }
`

//------------------------------------- To Add and Update Study Abroad Exam ------------------

export const UPDATE_USER_STUDY_ABROAD_EXAM = gql`
  mutation updateStudyAbroadExam(
    $id: ID!
    $eContent: [editComponentPluginStudyAbroadExamInput]
  ) {
    updateUser(
      input: { where: { id: $id }, data: { studyAbroadExamContent: $eContent } }
    ) {
      user {
        studyAbroadExamContent {
          id
          studyAbroadExam {
            id
            examName
          }
          markValue
        }
      }
    }
  }
`
//----------------------------------- To Upload image -----------------------------------------

export const UPLOAD_IMAGE = gql`
  mutation addImage($file: Upload!) {
    upload(file: $file) {
      name
      id
      url
    }
  }
`
//----------------------------------- To update profile verification -----------------------------------------

export const PROFILE_VERIFICATION = gql`
  mutation profileVerfication(
    $userId: ID!
    $email: String!
    $otp: String!
    $userName: String!
    $phone: String!
  ) {
    createProfileVerification(
      input: {
        data: {
          email: $email
          user: $userId
          otp: $otp
          userName: $userName
          phoneNumber: $phone
        }
      }
    ) {
      profileVerification {
        id
        email
        phoneNumber
        otp
      }
    }
  }
`

// --------------------------------  To update user profile  image -------------------------------

export const USER_IMAGE_UPDATE = gql`
  mutation updateUserImage($userId: ID!, $profileImageContent: String!) {
    updateUser(
      input: {
        where: { id: $userId }
        data: { profileImageContent: $profileImageContent }
      }
    ) {
      user {
        profileImageContent
      }
    }
  }
`
// --------------------------------  To update user mobile otp -------------------------------

export const USER_MOBILE_OTP_UPDATE = gql`
  mutation updateMobileOTP($userId: ID!, $otp: String!) {
    updateUser(input: { where: { id: $userId }, data: { mobileOTP: $otp } }) {
      user {
        mobileOTP
      }
    }
  }
`

// --------------------------------  To update user mail otp -------------------------------

export const USER_MAIL_OTP_UPDATE = gql`
  mutation updateMailOTP($userId: ID!, $otp: String!) {
    updateUser(input: { where: { id: $userId }, data: { mailOTP: $otp } }) {
      user {
        mailOTP
      }
    }
  }
`

// --------------------------------  To update user mobile verify status -------------------------------

export const USER_MOBILE_VERIFY_STATUS_UPDATE = gql`
  mutation updateMobileStatus($userId: ID!, $status: Boolean!) {
    updateUser(
      input: { where: { id: $userId }, data: { isMobileVerified: $status } }
    ) {
      user {
        isMobileVerified
      }
    }
  }
`

// --------------------------------  To update user mail verify status -------------------------------

export const USER_MAIL_VERIFY_STATUS_UPDATE = gql`
  mutation updateMailStatus($userId: ID!, $status: Boolean!) {
    updateUser(
      input: { where: { id: $userId }, data: { isMailVerified: $status } }
    ) {
      user {
        isMailVerified
      }
    }
  }
`

// ----------------------------- To create University review ----------------------------------

export const CREATE_UNIVERSITY_REVIEW = gql`
  mutation createReview(
    $title: String!
    $description: String!
    $averageRating: Float!
    $user: ID!
    $university: ID!
    $ratings: ComponentPluginRatingInput!
  ) {
    createReview(
      input: {
        data: {
          title: $title
          description: $description
          averageRating: $averageRating
          user: $user
          university: $university
          ratings: $ratings
          published_at: null
        }
      }
    ) {
      review {
        title
      }
    }
  }
`

// --------------------------------  To update user data -------------------------------

export const UPDATE_USER_DATA = gql`
  mutation updateJourney(
    $userid: ID!
    $role: ID!
    $studyExamContent: [editComponentPluginStudyAbroadExamInput]
    $journeyContent: [editComponentPluginJourneySubContentInput]
  ) {
    updateUser(
      input: {
        where: { id: $userid }
        data: {
          userRole: $role
          studyAbroadExamContent: $studyExamContent
          journeyStepContent: $journeyContent
        }
      }
    ) {
      user {
        email
        secondaryEmail
        userRole {
          id
          name
        }
        studyAbroadExamContent {
          studyAbroadExam {
            id
          }
        }
        journeyStepContent {
          id
          journeystep {
            id
            name
          }
          position
        }
      }
    }
  }
`
//----------------------------------------- To get user firstname ---------------------------------------

// export const GET_USER_NAME_QUERY = gql`
//   query getName($id:ID!){
//     user(id:$id){
//       firstName
//     }
//   }
// `

//-------------------------------------------To get user name ------------------------------------------------

export const GET_USER_ID_MUTATION = gql`
  mutation getUserID($userid: ID!) {
    updateUser(input: { where: { id: $userid } }) {
      user {
        firstName
      }
    }
  }
`
// --------------------------------------------- To get journey block static content ----------------------------
export const JOURNEY_BLOCK = gql`
  query {
    journeyBlock {
      title
      description
    }
  }
`
//----------------------------------------------- To get blog topics ---------------------------------------------

export const BLOG_TOPICS_QUERY = gql`
  query {
    articleTopics {
      id
      title
      url
      thumbnail {
        id
        url
        name
      }
      altThumbnailName
    }
  }
`
//-------------------------------------------------- To get blog detail data and university detail data -------------------------------------

export const BLOG_DETAILS_DATA = gql`
  query getBlogDetails($id: ID!) {
    article(id: $id) {
      id
      author {
        id
        firstName
        lastName
        biography
        profileImage {
          id
          name
          url
        }
        altProfileName
        userRole {
          id
          name
        }
      }
      topic {
        id
        title
      }
      title
      url
      description
      sectionContent {
        id
        title
        description
        position
      }
      wishlistCount
      favoriteCount
      ReadingTimeInMinutes
      blogDate
      image {
        id
        url
        name
      }
      bannerImage {
        id
        url
        name
      }
      metaDescription
    }
  }
`
export const UNIVERSITY_PROGRAMME_DETAIL = gql`
  query getUniversityDetails($id: ID!, $limit: Int) {
    university(id: $id) {
      reviews(sort: "createdAt:desc", limit: $limit) {
        id
        title
        averageRating
        createdAt
        description

        user {
          id
          username
          profileImage {
            id
            name
            url
          }
          batch
          cityName
          firstName
          lastName
          countryName {
            countryName
          }
        }
      }
      programmes(limit: $limit) {
        id
        Title
        ProgrammeInfo {
          id
          Title
          Content
          Date
        }
      }
    }
  }
`

export const UNIVERSITY_CAMPUSES_DATA = gql`
  query getUniversityCampuses($id: ID!) {
    university(id: $id) {
      id
      universityName

      aboutPage {
        id
        geoLocation{
        latitude
        longitude
      }
        universities {
          id
          universityName
          url
          thumbnailImage {
            id
            name
            url
          }
          aboutPage {
            id
            contactInformation {
              id
              address
            }
          }
        }
      }
    }
  }
`
export const UNIVERSITY_DETAIL_DATA = gql`
  query getUniversityDetails($id: ID!, $limit: Int) {
    university(id: $id) {
      id
      wishlistCount
      universityLogo {
        id
        name
        url
      }
      favoriteCount

      admission {
        id
        description {
          id
          title
          description
          subContent {
            title
            description
          }
        }
        requirements {
          id
          title
          subTitle
          weblink
          weblinkTitle
          subRequirement {
            title
            id
            description
            examscore
            examScore2
            image1 {
              id
              name
              url
            }
            image2 {
              id
              url
              name
            }
          }
        }
        applicationDeadline {
          id
          title
          subtitle
          programmeTitle
          sessionTitle
          sessionTitle2
          programmeTitle2
          programmeDescription
          sessionDescription
          programmes {
            id
            programme
            applicationDeadlines
          }
          sessions {
            id
            session
            applicationDeadlines
          }
        }
      }

      universitySubtitle
      establishedIn
      worldUniversityRanking
      accreditedBy
      institutionType
      ratings {
        id
        academic
        infrastructure
        accomodation
        extracurricular
        faculty
        placement
      }

      reviews(sort: "createdAt:desc", limit: 3) {
        id
        title
        averageRating
        createdAt
        description

        user {
          id
          username
          profileImage {
            id
            name
            url
          }
          batch
          cityName
          firstName
          lastName
          countryName {
            countryName
          }
        }
      }

      programmes(limit: $limit) {
        id
        Title
        ProgrammeInfo {
          id
          Title
          Content
          Date
        }
      }

      universityName

      feeFirstYearAmount
      firstYearFeeCurrencySymbol
      sliderContent {
        id
        altImageName
        Images {
          id
          name
          url
        }
      }

      courseDuration {
        value
        title
      }
      tution_fee_ranges {
        range
      }
      examScores {
        value
        title
      }
      stateName {
        stateName
      }
      countryName {
        countryName
      }
      course_format {
        courseFormat
      }
      course_names {
        courseName
      }
      universitySubtitle

      aboutPage {
        id
        geoLocation{
        latitude
        longitude
      }
        contactInformation {
          id
          phone
          address
          mapImage {
            id
            name
            url
          }
          website
        }
        contactInformation {
          phone
          address
          website
          mapImage {
            name
            caption
            alternativeText
            url
          }
        }
  
        admissionContent {
          id
          description
          title

          subContent {
            id
            title
            description
          }
          position
        }
        universityServicesContent {
          id
          mainTitle
          subContent {
            id
            title
            description
            images {
              name
              url
            }
          }
        }
        studentLifeContent {
          id
          title
          description
          subContent {
            title
            description
          }
        }
        accreditationContent {
          id
          title
          description
        }
      
        overviewDescription {
          id
          title
          description
        }
      }
      rating
      url
      recommended
      brochureValue {
        id
        name
        url
        caption
      }
      course_names {
        id
        courseName
      }
      course_specialization {
        id
        type
      }
      attendance {
        id
        type
      }
      feeFirstYearAmount
      firstYearFeeCurrencySymbol
      tution_fee_ranges {
        id
        range
      }
      course_format {
        courseFormat
        id
      }
      thumbnailImage {
        id
        name
        url
      }
      stateName {
        id
        stateName
      }
      countryName {
        id
        countryName
      }
      course_names {
        id
        courseName
      }

      course_duration {
        id
        duration
      }
      courseDuration {
        id
        value
        title
      }
      applicationDeadline {
        id
        value
        title
      }
      examScores {
        id
        value
        title
      }
    }
  }
`
//-------------------------------------------------- To get blog info ------------------------------------

export const BLOG_DETAILS_INFO = gql`
  query getArticlesInfo($type: String, $limit: Int) {
    articleTypes(where: { type: $type }, limit: 1) {
      id
      type
      articles(limit: $limit, sort: "createdAt:desc") {
        id
        createdAt
        author {
          id
          firstName
          lastName
          biography
          profileImage {
            id
            name
            url
          }
          altProfileName
          userRole {
            id
            name
          }
        }
        topic {
          id
          title
          thumbnail {
            id
            name
            url
          }
          altThumbnailName
        }
        title
        url
        description
        image {
          id
          name
          url
        }
        sectionContent {
          id
          title
          description
          position
        }
        wishlistCount
        favoriteCount
        ReadingTimeInMinutes
        blogDate
      }
    }
  }
`
//------------------------------------------------------ Search by blog name -----------------------------------

export const SEARCH_BLOG_BY_TITLE = gql`
  query getArticlesInfo($searchValue: String) {
    articles(where: { title_contains: $searchValue }) {
      id
      articleTypes {
        id
        type
      }
      author {
        id
        firstName
        lastName
        biography
        profileImage {
          id
          name
          url
        }
        altProfileName
        userRole {
          id
          name
        }
      }
      topic {
        id
        title
        thumbnail {
          id
          name
          url
        }
        altThumbnailName
      }
      title
      url
      description
      image {
        id
        name
        url
      }
      sectionContent {
        id
        title
        description
        position
      }
      wishlistCount
      favoriteCount
      ReadingTimeInMinutes
      blogDate
    }
  }
`

// ---------------------------------------------------- search by blog topic ------------------------------------

export const SEARCH_BLOG_BY_TOPICS = gql`
  query getArticlesInfo($searchValue: String) {
    articles(where: { topic: { title_contains: $searchValue } }) {
      id
      author {
        id
        firstName
        lastName
        biography
        profileImage {
          id
          name
          url
        }
        altProfileName
        userRole {
          id
          name
        }
      }
      topic {
        id
        title
        thumbnail {
          id
          name
          url
        }
        altThumbnailName
      }
      title
      url
      description
      image {
        id
        name
        url
      }
      sectionContent {
        id
        title
        description
        position
      }
      wishlistCount
      favoriteCount
      ReadingTimeInMinutes
      blogDate
    }
  }
`

//--------------------------------------------- To check favourite articles for user -------------------------------------

export const CHECK_FAVOURITE_ARTICLES_FOR_USER = gql`
  query checkArticles($id: ID!, $blogid: ID) {
    user(id: $id) {
      favouriteArticles(where: { id: $blogid }) {
        id
        title
      }
    }
  }
`
//--------------------------------------------- To check bookmark articles for user -------------------------------------

export const CHECK_BOOKMARK_ARTICLES_FOR_USER = gql`
  query checkArticles($id: ID!, $blogid: ID) {
    user(id: $id) {
      bookmarkArticles(where: { id: $blogid }) {
        id
        title
      }
    }
  }
`
//------------------------------------------- To get all favourites articles and wishlisted universities for user ------------------------------------
export const GET_ALL_FAVOURITE_ARTICLES_USER = gql`
  query checkAllArticles($id: ID!) {
    user(id: $id) {
      favouriteArticles {
        id
        title
      }
    }
  }
`
export const GET_ALL_WISHLISTED_UNIVERSITIES_USER = gql`
  query checkAllUniversities($id: ID!) {
    user(id: $id) {
      wishlisted_universities {
        id
        universityName
      }
    }
  }
`
//------------------------------------------- To get all favourites articles for user ------------------------------------
export const GET_ALL_BOOKMARK_ARTICLES_USER = gql`
  query checkAllArticles($id: ID!) {
    user(id: $id) {
      bookmarkArticles {
        id
        title
      }
    }
  }
`
//------------------------------------- To remove favourite artiles and wishlisted universities from user --------------------------------------------

export const UPDATE_FAVOURITE_ARTICLES_USER = gql`
  mutation updateUserArticles($id: ID!, $articleID: [ID]) {
    updateUser(
      input: { where: { id: $id }, data: { favouriteArticles: $articleID } }
    ) {
      user {
        favouriteArticles {
          id
        }
      }
    }
  }
`
export const UPDATE_WISHLIST_UNIVERSITIES_USER = gql`
  mutation updateUserWishlists($id: ID!, $wishlistID: [ID]) {
    updateUser(
      input: {
        where: { id: $id }
        data: { wishlisted_universities: $wishlistID }
      }
    ) {
      user {
        id
        username
        wishlisted_universities {
          universityName
          id
        }
      }
    }
  }
`
//------------------------------------- To remove bookmark artiles from user --------------------------------------------

export const UPDATE_BOOKMARK_ARTICLES_USER = gql`
  mutation updateUserArticles($id: ID!, $articleID: [ID]) {
    updateUser(
      input: { where: { id: $id }, data: { bookmarkArticles: $articleID } }
    ) {
      user {
        bookmarkArticles {
          id
        }
      }
    }
  }
`
//---------------------------------------- To update article favourite count and update university wishlist count--------------------------------------
export const UPDATE_FAVOURITE_ARTICLES_DATA = gql`
  mutation updateArticle($id: ID!, $favoriteCount: Int) {
    updateArticle(
      input: { where: { id: $id }, data: { favoriteCount: $favoriteCount } }
    ) {
      article {
        id
        title
        favoriteCount
      }
    }
  }
`
export const UPDATE_WISHLIST_UNIVERSITIES_DATA = gql`
  mutation updateUniversity($id: ID!, $wishlistCount: Int) {
    updateUniversity(
      input: { where: { id: $id }, data: { wishlistCount: $wishlistCount } }
    ) {
      university {
        id
        universityName
        wishlistCount
      }
    }
  }
`
//---------------------------------------- To update article bookmark count --------------------------------------
export const UPDATE_BOOKMARK_ARTICLES_DATA = gql`
  mutation updateArticle($id: ID!, $wishlistCount: Int) {
    updateArticle(
      input: { where: { id: $id }, data: { wishlistCount: $wishlistCount } }
    ) {
      article {
        id
        title
        wishlistCount
      }
    }
  }
`
//------------------------------------------------------ Search by blog description -----------------------------------

export const SEARCH_BLOG_BY_DESCRIPTION = gql`
  query getArticlesInfo($searchValue: String) {
    articles(where: { description_contains: $searchValue }) {
      id
      articleTypes {
        id
        type
      }
      author {
        id
        firstName
        lastName
        biography
        profileImage {
          id
          name
          url
        }
        altProfileName
        userRole {
          id
          name
        }
      }
      topic {
        id
        title
        thumbnail {
          id
          name
          url
        }
        altThumbnailName
      }
      title
      url
      description
      image {
        id
        name
        url
      }
      sectionContent {
        id
        title
        description
        position
      }
      wishlistCount
      favoriteCount
      ReadingTimeInMinutes
      blogDate
    }
  }
`

// ------------------------------- To get particular user wishlist and bookmark -----------------------

export const USER_WISHLIST_BOOKMARK_QUERY = gql`
  query userData($id: ID!) {
    user(id: $id) {
      id
      username
      email
      wishlisted_universities {
        id
        universityName
        rating
        universitySubtitle
        thumbnailImage {
          id
          url
          caption
        }
        stateName {
          id
          stateName
        }
        countryName {
          id
          countryName
        }
        course_names {
          id
          courseName
        }
        course_format {
          courseFormat
        }
        wishlistCount
      }
      secondaryEmail
      firstName
      lastName
      favouriteArticles {
        id
        articleTypes {
          id
          type
        }
        author {
          id
          firstName
          lastName

          profileImage {
            id
            name
            url
          }
          altProfileName
          userRole {
            id
            name
          }
        }
        topic {
          id
          title
          thumbnail {
            id
            name
            url
          }
          altThumbnailName
        }
        title
        url
        description
        image {
          id
          name
          url
        }
        sectionContent {
          id
          title
          description
          position
        }
        wishlistCount
        favoriteCount
        ReadingTimeInMinutes
        blogDate
      }
      bookmarkArticles {
        id
        articleTypes {
          id
          type
        }
        author {
          id
          firstName
          lastName

          profileImage {
            id
            name
            url
          }
          altProfileName
          userRole {
            id
            name
          }
        }
        topic {
          id
          title
          thumbnail {
            id
            name
            url
          }
          altThumbnailName
        }
        title
        url
        description
        image {
          id
          name
          url
        }
        sectionContent {
          id
          title
          description
          position
        }
        wishlistCount
        favoriteCount
        ReadingTimeInMinutes
        blogDate
      }
    }
  }
`
//---------------------------------------------Home Page Query --------------------------

export const HOMEPAGE_QUERY = gql`
  query {
    homePage {
      id
      title
      metaDescription
      bannerTitle
      bannerImage {
        id
        name
        url
      }
      bannerImageAltName
      bannerDescription
      bannerButtonName
      unwrapSliderMainTitle
      unwrapContent {
        id
        title
        sliderImageValue {
          id
          name
          url
        }
        sliderImageAltName
        unwrapSubContent {
          id
          title
          thumbnailIcon {
            id
            name
            url
          }
          thumbnailIconAltName
          description
        }
      }
      leaveNothingSliderMainTitle
      leaveNothingContent {
        id
        title
        image {
          id
          name
          url
        }
        imageAltName
        description
      }
      alternateDimensionMainTitle
      alternateDimensionContent {
        id
        title
        thumbnailIcon {
          id
          name
          url
        }
        thumbnailIconAltName
        description
      }
      storiesContent {
        id
        mainTitle
        subTitle
        quoteContent
      }
      reviewContent {
        id
        profileName
        designationName
        profileImage {
          id
          name
          url
        }
        profileImageAltName
        comments
        socialMediaContent {
          id
          socialIconURL
          socialMediaIcon {
            id
            name
            url
          }
        }
      }
      ourBlogs(limit: 3) {
        id
        topic {
          id
          title
          thumbnail {
            id
            name
            url
          }
          altThumbnailName
        }
        title
        url
        image {
          id
          name
          url
        }
        author {
          id
          firstName
          lastName
          profileImage {
            id
            name
            url
          }
          altProfileName
        }
        blogDate
        wishlistCount
        favoriteCount
        ReadingTimeInMinutes
      }
      partnerContent {
        id
        mainTitle
        subTitle
        partnerImage {
          id
          logoImage {
            id
            name
            url
          }
          logoAltName
        }
      }
    }
  }
`
//-----------------------------------------Home page Newsletter -----------------------------------------
export const HOMEPAGE_NEWSLETTER_QUERY = gql`
  query {
    homepageNewsletter {
      id
      title
      description
      thumbnailImage {
        id
        name
        url
      }
      thumbnailImageAltName
      buttonName
    }
  }
`
//------------------------------------------About US Page ----------------------------------------------------
export const ABOUT_US_QUERY = gql`
  query {
    aboutUsPage {
      id
      bannerTitle
      bannerImage {
        id
        name
        url
      }
      bannerImageAltName
      bannerDescription
      aboutDimensionsContent {
        id
        title
        description
        image {
          id
          name
          url
        }
        imageAltName
      }
      ourStoryMainTitle
      ourStoryContent {
        id
        year
        title
        thumbnailImage {
          id
          name
          url
        }
        thumbnailImageAltName
        description
      }
      ourMissionContent {
        id
        title
        description
        image {
          id
          name
          url
        }
        imageAltName
      }
      ourValuesContent {
        id
        mainTitle
        thumbnailImage {
          id
          name
          url
        }
        thumbnailImageAltName
        ourValueSubContent {
          id
          title
          description
          image {
            id
            url
            name
          }
          imageAltName
        }
      }
      ourCultureContent {
        id
        title
        description
        image {
          id
          name
          url
        }
        imageAltName
        description
      }
      joinUSContent {
        id
        mainTitle
        description
        buttonName
      }
      getInTouchContent {
        id
        mainTitle
        description
        buttonName
        thumbnailImage {
          id
          name
          url
        }
        thumnailImageAltName
      }
      title
      metaDescription
    }
  }
`
//---------------------------------------------------Contact US Query --------------------------------
export const CONTACT_US_QUERY = gql`
  query {
    contactUsPage {
      id
      formTitle
      formDescription
      getInTouchMainContent {
        id
        title
        description
      }
      getInTouchContent {
        id
        description
        getInTouchSubContent {
          id
          thumbnailIcon {
            id
            name
            url
          }
          thumbnailContent
        }
      }
      followUsContent {
        id
        title
        description
        socialMediaContent {
          id
          socialIconURL
          socialMediaIcon {
            id
            name
            url
          }
        }
      }
      title
      metaDescription
    }
  }
`
//---------------------------------------------- Mutation for contact us information --------------------------

export const CONTACT_INFO_MUTATION = gql`
  mutation contactInfo(
    $firstName: String
    $lastName: String
    $email: String
    $contactNumber: String
    $subject: String
    $message: String
  ) {
    createContactUsInformation(
      input: {
        data: {
          firstName: $firstName
          lastName: $lastName
          email: $email
          contactNumber: $contactNumber
          subject: $subject
          message: $message
        }
      }
    ) {
      contactUsInformation {
        id
        firstName
        lastName
        email
        contactNumber
        subject
        message
      }
    }
  }
`
//---------------------------------------------------Mutation for user profile image-------------------------------------

export const UPDATE_USER_PROFILE_IMAGE_MUTATION = gql`
  mutation updateUserProfileImage($id: ID!, $imageId: ID!) {
    updateUser(
      input: { where: { id: $id }, data: { profileImage: $imageId } }
    ) {
      user {
        profileImage {
          id
          url
          name
        }
      }
    }
  }
`
// --------------------------------  To update user secondary mail -------------------------------

export const USER_SECONDARY_MAIL_UPDATE = gql`
  mutation updatesecondaryEmail($userId: ID!, $email: String!) {
    updateUser(
      input: { where: { id: $userId }, data: { secondaryEmail: $email } }
    ) {
      user {
        secondaryEmail
      }
    }
  }
`
//------------------------------------- To get user id from email -----------------------------------
export const GET_USER_ID_QUERY = gql`
  query getUserID($email: String) {
    users(where: { email: $email }) {
      id
      email
    }
  }
`
//-------------------------------------- To Update user forget password code ----------------------------
export const UPDATE_USER_FORGOT_PASSWORD_CODE_MUTATION = gql`
  mutation UpdateUserForgotCode($id: ID!, $code: String) {
    updateUser(
      input: { where: { id: $id }, data: { forgetPasswordCode: $code } }
    ) {
      user {
        id
        forgetPasswordCode
      }
    }
  }
`
//---------------------------------------Mutation for Forgot password user ----------------------------
export const CREATE_FORGOT_PASSWORD_USER_MUTATION = gql`
  mutation createForgotPassword($email: String, $code: String) {
    createForgotPasswordRecord(
      input: { data: { email: $email, code: $code } }
    ) {
      forgotPasswordRecord {
        id
        email
        code
      }
    }
  }
`
//-----------------------------------------Mutation for change password user ----------------------------
export const UPDATE_USER_PASSWORD_MUTATION = gql`
  mutation changePassword($userId: ID!, $pwd: String) {
    updateUser(input: { where: { id: $userId }, data: { password: $pwd } }) {
      user {
        id
        email
      }
    }
  }
`

// ---------------------------------- Mutation for delete user acccount ------------------------------------

export const DELETE_USER_MUTATION = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(input: { where: { id: $id } }) {
      user {
        id
      }
    }
  }
`
//------------------------------------------ Query to get user forgot password code -------------------------
export const GET_USER_FORGOT_PASS_CODE = gql`
  query userForgetCode($email: String) {
    users(where: { email: $email }, limit: 1) {
      id
      email
      forgetPasswordCode
    }
  }
`
//----------------------------------------Privacy Policy Query --------------------------------------------
export const PRIVACY_POLICY_QUERY = gql`
  query {
    privacyPolicy {
      id
      title
      description
      metaDescription
    }
  }
`
//----------------------------------------Cookie Policy Query --------------------------------------------
export const COOKIE_POLICY_QUERY = gql`
  query {
    cookiePolicy {
      id
      title
      description
      metaDescription
    }
  }
`
//------------------------------------ Study Detail Page from URL -----------------------------------------

export const STUDY_DETAIL_URL = gql`
  query studyListing($url: String) {
    studyListings(where: { url: $url }, limit: 1) {
      id
      listingTitle
      url
    }
  }
`
//----------------------------------- Blog Detail Page, University Detail and Service Detail page from URL --------------------------------------------

export const BLOG_DETAIL_URL = gql`
  query articles($url: String) {
    articles(where: { url: $url }, limit: 1) {
      id
      title
      url
    }
  }
`
export const UNIVERSITY_DETAIL_URL = gql`
  query universities($url: String) {
    universities(where: { url: $url }, limit: 1) {
      id
      universityName
      url
    }
  }
`

//--------------------------------------Blog Topic from URL ------------------------------------------------

export const BLOG_TOPIC_URL = gql`
  query articleTopics($url: String) {
    articleTopics(where: { url: $url }, limit: 1) {
      id
      title
      url
    }
  }
`
//---------------------------------------Blog SEO Block Query ------------------------------------------------

export const BLOG_SEO_BLOCK_QUERY = gql`
  query {
    blogSeoBlock {
      id
      title
      metaDescription
    }
  }
`
//------------------------------------------------Query for article topic SEO ----------------------------------------------

export const BLOG_TOPIC_SEO_QUERY = gql`
  query getArticlesbyTopicSEO($selectedTopicID: ID!) {
    articleTopic(id: $selectedTopicID) {
      title
      metaDescription
    }
  }
`
//-------------------------------------------------- Mutation for journey block ------------------------------------

export const MUTATION_USER_JOURNEY = gql`
  mutation updateJourney(
    $userid: ID!
    $journeyContent: [editComponentPluginJourneySubContentInput]
  ) {
    updateUser(
      input: {
        where: { id: $userid }
        data: { journeyStepContent: $journeyContent }
      }
    ) {
      user {
        id
        email
        secondaryEmail
        journeyStepContent {
          id
          journeystep {
            id
            name
          }
          position
        }
      }
    }
  }
`

////--------------Universities-------------
export const UNIVERSITIES_DETAILS = gql`
  query {
    universities {
      id
      rating
      url
      brochureValue {
        id
        name
        url
        caption
      }
      feeFirstYearAmount
      firstYearFeeCurrencySymbol
      course_specialization {
        id
        type
      }

      course_duration {
        id
        duration
      }

      tution_fee_ranges {
        id
        range
      }
      course_format {
        courseFormat
        id
      }

      thumbnailImage {
        id
        name
        url
      }
      stateName {
        id
        stateName
      }
      countryName {
        countryName
      }
      course_names {
        id
        courseName
      }

      universityName
      universitySubtitle

      courseDuration {
        id
        value
        title
      }
      applicationDeadline {
        id
        value
        title
      }
      course_specialization {
        id
        type
      }
      examScores {
        id
        value
        title
      }
    }
  }
`

export const FILTER_DETAILS = gql`
  query {
    universitiesConnection {
      groupBy {
        course_specialization {
          connection {
            values {
              course_specialization {
                id
                type
              }
            }
            aggregate {
              count
            }
          }
        }
        attendance {
          connection {
            values {
              attendance {
                id
                type
              }
            }
            aggregate {
              count
            }
          }
        }

        course_duration {
          connection {
            values {
              course_duration {
                id
                duration
              }
            }
            aggregate {
              count
            }
          }
        }
        course_format {
          connection {
            values {
              id
              course_format {
                id
                courseFormat
              }
            }
            aggregate {
              count
            }
          }
        }
        stateName {
          key
          connection {
            values {
              stateName {
                id
                stateName
              }
            }
            aggregate {
              count
            }
          }
        }
      }
    }

    tutionFees {
      id
      range
    }
    courseDurations {
      id
      duration
    }

    courses {
      id
      courseName
    }
    attendances {
      id
      type
    }

    courseSpecializations {
      id
      type
    }

    states {
      id
      stateName
    }
    courseTimes {
      id
      courseFormat
    }
  }
`
//////////Initial tution fees collection------------------------

export const UNIVERSITIES_FEES_RANGE = gql`
  query universitiesFeesRange(
    $id: [String]
    $stateName: [String]
    $courseFormat: [String]
    $sort: String
    $courseDurations: [String]
    $attendance: [String]
    $course_specialization: [String]
    $course_names: [String]
  ) {
    universities(
      where: {
        tution_fee_ranges: { id: $id }
        stateName: { id: $stateName }
        course_format: { id: $courseFormat }
        course_duration: { id: $courseDurations }
        attendance: { id: $attendance }
        course_specialization: { id: $course_specialization }
        course_names: { id: $course_names }
      }
      sort: $sort
    ) {
      id
      rating
      url
      recommended
      brochureValue {
        id
        name
        url
        caption
      }
      course_names {
        id
        courseName
      }
      course_specialization {
        id
        type
      }
      attendance {
        id
        type
      }
      feeFirstYearAmount
      firstYearFeeCurrencySymbol
      tution_fee_ranges {
        id
        range
      }
      course_format {
        courseFormat
        id
      }
      thumbnailImage {
        id
        name
        url
      }
      stateName {
        id
        stateName
      }
      countryName {
        id
        countryName
      }
      course_names {
        id
        courseName
      }

      universityName
      universitySubtitle

      course_duration {
        id
        duration
      }
      courseDuration {
        id
        value
        title
      }
      applicationDeadline {
        id
        value
        title
      }
      examScores {
        id
        value
        title
      }
    }
  }
`
export const STATES_CHECK = gql`
  query {
    universities(where: { stateName: { stateName: "Paris" } }) {
      id
      stateName {
        stateName
      }
      tution_fee_ranges {
        range
      }
      course_names {
        courseName
      }
      course_format {
        courseFormat
      }
      course_duration {
        duration
      }
    }
  }
`
export const GET_ALL_CITIES = gql`
  query{
    cities{
      id
      cityName
    }
  }
`
export const GET_COMPANY_INFO = gql`
  query{
    companyInfo{
      id
        companyAddress
        emailAddress
        contactNumber
        websiteURL
    }
  }
`
export const GET_STUDENT_BUDGET_PARAMETER_VALUES = gql`
  query getAllCities($id: ID!){
    city(id:$id){
      id
      cityName
      accommodations{
        id
        accommodationType
        accommodationPrice
      }
      meals{
        id
        mealType
        mealPrice
      }
      drinks{
        id
        drinkType
        drinkPrice
      }
      groceries{
        id
        groceryItemName
        groceryItemPrice
      }
      transportation{
        id
        transportationType
        transportationCost
      }
      personal_cares{
        id
        itemName
        itemCost
      }
      entertainments{
        id
        entertainmentType
        entertainmentCost
      }
      
    }
  }
`
//////////// services query sections -----------------------------
export const GET_SERVICES = gql`
query{
  servicesMainPage{
    id
    title
    description
    image{
      id
      url
    }
    servicesCategoryContent{
      id
      mainPageTitle
      mainPageDescription
      mainPageSequence
      url
    }
    firstSectionExtraContentTitle
    firstSectionExtraContentDescription
    firstSectionExtraContentButtonName
    faqSectionTitle
    faqSectionContent{
      id
      questionTitle
      questionAnswerDescription
      questionSequence
    }
    faqSectionImage{
      id
      url
    }
  }
}
`

export const SERVICE_DETAIL_URL = gql`
query servicesCategoryPages($url:String){
 
  servicesCategoryPages(where:{url: $url}, limit: 1){
    id
    url
    title
    description
    image{
      id
      url
    }
    leftSideDescription
    rightSideDescriptionTitle
    rightSideDescription
    rightSideDescriptionButtonName
    rightSideDescriptionButtonType
    rightsideDescriptionButtonURL
    secondSectionTitle
    secondSectionDescription
    secondSectionImage{
      id
      url
    }
    secondSectionButtonName
    thirdSectionTitle
    thirdSectionDescription
    servicesCategoryContent{
      id
      mainPageTitle
      mainPageDescription
      mainPageSequence
      url
    }
    categoryExtraContentTitle
    categoryExtraContentDescription
    categoryExtraContentButtonName
    faqSectionTitle
    faqSectionImage{
      id
      url
    }
    faqSectionContent{
      id
      questionTitle
      questionAnswerDescription
      questionSequence
    }
  }
}
`

export const PRICING_PAGE_URL= gql`
query {
  pricingPage {
    id
    title
    description
    image {
      id
      name
      url
    }
    
    individualPricing
    createBundleButton
  
    pricingFooterContent
    
    pricingCategoryContent {
      id
      title
      isBundleProducts
      pricingCategoryServices {
        id
        title
        serviceProducts {
          id
          serviceTitle
          serviceImage {
            id
            name
            url
          }
          servicePrice {
            id
            currencyType
            servicePrice
            currencySymbol
          }
        }
      }
      pricingCategoryBundle {
        id
        bundleName
        bundleImage {
          id
          name
          url
        }
        bundleDescription
        bundlePricing {
          id
          currencyType
          servicePrice
          currencySymbol
        }
        bonusSection
      }
    }
  }
}`

export const PRICING_SUMMARY_PAGE=gql`
query {
  pricingPage {
    summaryPageTitle
    summeryFooterContent
    summaryPageDescription
    summaryPageImage {
      id
      name
      url
    }
    bundleDiscountPrice
  }}
`
export const PRICING_CREATE_PAYMENT_INFO = gql`
  mutation createPayment($email: String, $transactionAmount: Float,
    $transactionCurrency:String,$transactionStatus:String,$razorpayPaymentID:String,
      $razorpayOrderID:String,$phoneNumber:String,$userId:String,,$items:String){
      createPricingTransactionInformation(input:{
        data:{
        email:$email,
        phoneNumber:$phoneNumber,
        transactionAmount:$transactionAmount,
        transactionCurrency:$transactionCurrency,
        transactionStatus:$transactionStatus,
        razorpayPaymentID:$razorpayPaymentID,
        razorpayOrderID:$razorpayOrderID,
        userId:$userId,
        items:$items
        }
      }){
        pricingTransactionInformation{
          email
          phoneNumber
          transactionAmount
          transactionCurrency
          transactionStatus
          razorpayPaymentID
          razorpayOrderID
          userId
          items
        }
      }
      
    }
`

export const FILTER_PAYMENT_BY_USERID = gql`
  query getPaymentInfo($userId:String){
    pricingTransactionInformations(sort:"createdAt:desc",where:{userId:$userId}){
      id
      email
      phoneNumber
      razorpayPaymentID
      transactionAmount
      transactionCurrency
      transactionStatus
      userId
      items
      createdAt
    }
  }
`